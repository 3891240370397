export const visuallyHidden = {
    // include `px` so we can use it with `sx`
    border: 0,
    clip: `rect(0, 0, 0, 0)`,
    height: `1px`,
    margin: `-1px`,
    overflow: `hidden`,
    padding: 0,
    position: `absolute`,
    whiteSpace: `nowrap`,
    width: `1px`,
  }